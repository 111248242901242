<template>
  <!-- <table class="quit-list" v-if="list !== null && list.length !== 0"> -->
  <table class="quit-list">
    <thead>
      <tr>
        <th class="serial"><p>#</p></th>
        <th class="username"><p>{{ t('components.quitList.username') }}</p></th>
        <th class="nickname"><p>{{ t('components.quitList.nickname') }}</p></th>
        <th class="total" @click="orderBy('total')"><p :class="order === 'total' ? (sort === 'asc' ? 'up' : (sort === 'desc' ? 'down' : '')) : ''">{{ t('components.quitList.totalMessage') }}</p></th>
        <th class="time" @click="orderBy('jtime')"><p :class="order === 'jtime' ? (sort === 'asc' ? 'up' : (sort === 'desc' ? 'down' : '')) : ''">{{ t('components.quitList.joinTime') }}</p></th>
        <th class="time" @click="orderBy('lmtime')"><p :class="order === 'lmtime' ? (sort === 'asc' ? 'up' : (sort === 'desc' ? 'down' : '')) : ''">{{ t('components.quitList.latestMsgTime') }}</p></th>
        <th class="time" @click="orderBy('qtime')"><p :class="order === 'qtime' ? (sort === 'asc' ? 'up' : (sort === 'desc' ? 'down' : '')) : ''">{{ t('components.quitList.quitTime') }}</p></th>
        <th class="by"><p>{{ t('components.quitList.operator') }}</p></th>
        <th class="reason"><p>{{ t('components.quitList.reason') }}</p></th>
        <th class="blocked"><p>{{ t('components.quitList.blacklist') }}</p></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in list" :key="index">
        <td :title="index + 1"><p>{{ index + 1 }}</p></td>
        <td :title="item.username"><p>{{ item.username }}</p></td>
        <td :title="item.nickname">
          <p class="nickname-td" @click="goMember(item.id)" :style="{ cursor: record ? 'pointer' : 'default' }">
            <span class="avatar" :style="{backgroundImage: `url(${item.avatar})`}"></span>
            <span class="name">{{ item.nickname }}</span>
          </p>
        </td>
        <td :title="item.total"><p>{{ item.total }}</p></td>
        <td :title="item.joinTime"><p>{{ item.joinTime }}</p></td>
        <td :title="item.latestMsgTime"><p>{{ item.latestMsgTime }}</p></td>
        <td :title="item.quitTime"><p>{{ item.quitTime }}</p></td>
        <td :title="item.quitBy"><p>{{ item.quitBy }}</p></td>
        <!--  -->
        <td v-if="item.quitReason === 'reg'" :title="t('components.quitList.reasonItem.reg')"><p>{{ t('components.quitList.reasonItem.reg') }}</p></td>
        <td v-else-if="item.quitReason === 'url'" :title="t('components.quitList.reasonItem.url')">{{ t('components.quitList.reasonItem.url') }}<p></p></td>
        <td v-else-if="item.quitReason === 'self'" :title="t('components.quitList.reasonItem.self')">{{ t('components.quitList.reasonItem.self') }}<p></p></td>
        <td v-else-if="item.quitReason === 'mention'" :title="t('components.quitList.reasonItem.mention')">{{ t('components.quitList.reasonItem.mention') }}<p></p></td>
        <td v-else-if="item.quitReason === 'markdown'" :title="t('components.quitList.reasonItem.markdown')">{{ t('components.quitList.reasonItem.markdown') }}<p></p></td>
        <td v-else-if="item.quitReason === 'verify'" :title="t('components.quitList.reasonItem.verify')">{{ t('components.quitList.reasonItem.verify') }}<p></p></td>
        <td v-else-if="item.quitReason === 'timeout'" :title="t('components.quitList.reasonItem.timeout')">{{ t('components.quitList.reasonItem.timeout') }}<p></p></td>
        <td v-else-if="item.quitReason === 'nickname'" :title="t('components.quitList.reasonItem.nickname')">{{ t('components.quitList.reasonItem.nickname') }}<p></p></td>
        <td v-else-if="item.quitReason === 'via'" :title="t('components.quitList.reasonItem.via')">{{ t('components.quitList.reasonItem.via') }}<p></p></td>
        <td v-else-if="item.quitReason === 'warn-via'" :title="t('components.quitList.reasonItem.warnVia')">{{ t('components.quitList.reasonItem.warnVia') }}<p></p></td>
        <td v-else :title="t('components.quitList.reasonItem.unknown')"><p>{{ t('components.quitList.reasonItem.unknown') }}</p></td>
        <!--  -->
        <td :title="item.blocked ? t('components.quitList.blocked.yes') : t('components.quitList.blocked.no')"><p>{{ item.blocked ? t('components.quitList.blocked.yes') : t('components.quitList.blocked.no') }}</p></td>
      </tr>
      <tr class="action-bar">
        <td colspan="7">
          <div class="operate">
            <div class="pagination">
              <div class="page">
                <a href="javascript:void(0)" class="left" @click="pagePrev()">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1024 1024" xml:space="preserve">
                    <path d="M220.9,513.4c0,17.7,6.6,33.7,17.7,45.8l4.7,4.7l45.8,45.8l395.6,395.6c26.4,26.4,69.9,26.4,96.5,0 c26.4-26.4,26.4-69.9,0-96.5L385.3,513.2l395.6-395.8c26.4-26.4,26.4-69.9,0-96.5C754.5-5.5,711-5.5,684.6,21.1L289,416.7 l-46.4,46.4l-3.8,3.8C227.5,479.1,220.7,495.5,220.9,513.4L220.9,513.4z"/>
                  </svg>
                </a>
                <span>
                  <em v-for="(item, index) in pagination" :key="index" :style="{cursor: isNaN(item) ? '' : 'pointer'}" @click="page(item)"><i :style="{ background: offset === item - 1 ? '#e54d26' : '#60b7ff' }">{{ item }}</i></em>
                </span>
                <a href="javascript:void(0)" class="right" @click="pageNext()">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1024 1024" xml:space="preserve">
                    <path d="M801,512.8c0-17.7-6.6-33.7-17.7-45.8l-4.7-4.7l-45.8-45.8L337.2,20.9c-26.4-26.4-69.9-26.4-96.5,0 c-26.4,26.4-26.4,69.9,0,96.5L636.6,513L241,908.8c-26.4,26.4-26.4,69.9,0,96.5c26.4,26.4,69.9,26.4,96.3-0.2l395.6-395.6l46.4-46.4 l3.8-3.8C794.4,547.1,801.2,530.7,801,512.8L801,512.8z"/>
                  </svg>
                </a>
              </div>
              <div class="all">
                <span>{{ t('components.quitList.totalLine', { total: total }) }}</span>
              </div>
              <div class="limit">
                <select v-model="limit">
                  <option value="10">{{ t('components.quitList.option.ten') }}</option>
                  <option value="20">{{ t('components.quitList.option.twenty') }}</option>
                  <option value="30">{{ t('components.quitList.option.thirty') }}</option>
                  <option value="50">{{ t('components.quitList.option.fifty') }}</option>
                  <option value="70">{{ t('components.quitList.option.seventy') }}</option>
                  <option value="100">{{ t('components.quitList.option.hundred') }}</option>
                </select>
              </div>
              <div class="reason-checkbox">
                <p @click="filterShow = true">{{ t('components.quitList.reasonFilter') }}</p>
                <ul v-if="filterShow" @mouseleave="filterBlur()">
                  <li>
                    <input type="checkbox" id="reason-checkbox-reg" v-model="reg">
                    <label for="reason-checkbox-reg">{{ t('components.quitList.reasonItem.reg') }}</label>
                  </li>
                  <li>
                    <input type="checkbox" id="reason-checkbox-url" v-model="url">
                    <label for="reason-checkbox-url">{{ t('components.quitList.reasonItem.url') }}</label>
                  </li>
                  <li>
                    <input type="checkbox" id="reason-checkbox-self" v-model="self">
                    <label for="reason-checkbox-self">{{ t('components.quitList.reasonItem.self') }}</label>
                  </li>
                  <li>
                    <input type="checkbox" id="reason-checkbox-mention" v-model="mention">
                    <label for="reason-checkbox-mention">{{ t('components.quitList.reasonItem.mention') }}</label>
                  </li>
                  <li>
                    <input type="checkbox" id="reason-checkbox-markdown" v-model="markdown">
                    <label for="reason-checkbox-markdown">{{ t('components.quitList.reasonItem.markdown') }}</label>
                  </li>
                  <li>
                    <input type="checkbox" id="reason-checkbox-verify" v-model="verify">
                    <label for="reason-checkbox-verify">{{ t('components.quitList.reasonItem.verify') }}</label>
                  </li>
                  <li>
                    <input type="checkbox" id="reason-checkbox-timeout" v-model="timeout">
                    <label for="reason-checkbox-timeout">{{ t('components.quitList.reasonItem.timeout') }}</label>
                  </li>
                  <li>
                    <input type="checkbox" id="reason-checkbox-nickname" v-model="nickname">
                    <label for="reason-checkbox-nickname">{{ t('components.quitList.reasonItem.nickname') }}</label>
                  </li>
                  <li>
                    <input type="checkbox" id="reason-checkbox-via" v-model="via">
                    <label for="reason-checkbox-via">{{ t('components.quitList.reasonItem.via') }}</label>
                  </li>
                  <li>
                    <input type="checkbox" id="reason-checkbox-warn-via" v-model="warnVia">
                    <label for="reason-checkbox-warn-via">{{ t('components.quitList.reasonItem.warnVia') }}</label>
                  </li>
                  <li>
                    <input type="checkbox" id="reason-checkbox-unknown" v-model="unknown">
                    <label for="reason-checkbox-unknown">{{ t('components.quitList.reasonItem.unknown') }}</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- <p class="no-data" v-if="list !== null && list.length === 0">{{ t('components.noData') }}</p> -->
</template>

<script>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useDialog } from '../utils/dialog.js'
import { computed, onMounted, reactive, toRefs, watch } from 'vue'

export default {
  name: 'QuitList',
  props: {
    total: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default: () => { return [] }
    }
  },
  emits: [
    'reload'
  ],
  setup(props, context) {
    const { t } = useI18n()
    const Store = useStore()
    const Dialog = useDialog()
    const Router = useRouter()
    const data = reactive({
      record: computed(() => Store.getters.get('record')),

      sort: 'desc',
      order: 'qtime',
      limit: 10,
      offset: 0,

      filterShow: false,
      filterChanged: false,
      reg: true,
      url: true,
      self: true,
      mention: true,
      markdown: true,
      verify: true,
      timeout: true,
      nickname: true,
      via: true,
      warnVia: true,
      unknown: true,

      pageCount: 0,
      pagination: []
    })

    onMounted(() => {
      query()
    })

    // 外部调用这里，这里再调用外部
    const query = () => {
      context.emit('reload', data.sort, data.order, data.limit, data.offset, data.reg, data.url, data.self, data.mention, data.verify, data.timeout, data.nickname, data.via, data.warnVia, data.unknown)
    }

    // 监听切换单页显示个数
    watch(() => data.limit, (newer, older) => {
      data.offset = 0
      query()
    })
    // 监听数据变更，计算分页
    watch(() => props.list, (newer, older) => {
      computedPagination()
    })
    // 监听 filter 是否变动
    watch(() => [data.reg, data.url, data.self, data.mention, data.markdown, data.verify, data.timeout, data.nickname, data.via, data.warnVia, data.unknown], () => {
      data.filterChanged = true
    })

    // 计算分页器
    const computedPagination = () => {
      const tmp = []
      let totalPage = 0
      if (props.total % data.limit === 0) {
        totalPage = props.total / data.limit
      } else {
        totalPage = Math.floor(props.total / data.limit) + 1
      }
      for (let i = 1; i <= totalPage; i++) {
        tmp.push(i)
      }
      data.pageCount = tmp.length
      if (tmp.length > 7) {
        if (data.offset < 4) {
          data.pagination = [tmp[0], tmp[1], tmp[2], tmp[3], tmp[4], '...', tmp[tmp.length - 1]]
        }
        if (data.offset >= 4 && data.offset < tmp.length - 4) {
          data.pagination = [tmp[0], '...', tmp[data.offset - 1], tmp[data.offset], tmp[data.offset + 1], '...', tmp[tmp.length - 1]]
        }
        if (data.offset >= tmp.length - 4) {
          data.pagination = [tmp[0], '...', tmp[tmp.length - 5], tmp[tmp.length - 4], tmp[tmp.length - 3], tmp[tmp.length - 2], tmp[tmp.length - 1]]
        }
      } else {
        data.pagination = tmp
      }
    }

    // 切页
    const pagePrev = () => {
      if (data.offset <= 0) return
      data.offset--
      query()
    }
    const page = item => {
      if (isNaN(item)) return
      if (data.offset === item - 1) return
      data.offset = item - 1
      query()
    }
    const pageNext = () => {
      if (data.offset >= data.pageCount - 1) return
      data.offset++
      query()
    }

    // 根据什么查询
    const orderBy = by => {
      data.offset = 0
      if (data.sort === 'desc') {
        data.sort = 'asc'
      } else {
        data.sort = 'desc'
      }
      data.order = by
      query()
    }

    const goMember = id => {
      if (!data.record) return

      Store.commit('setMember', { uid: id })
      Router.push({ name: 'Member' })
    }

    const filterBlur = () => {
      data.filterShow = false
      if (!data.filterChanged) return
      data.filterChanged = false
      if (!data.reg && !data.url && !data.self && !data.mention && !data.markdown && !data.verify && !data.timeout && !data.nickname && !data.via && !data.warnVia && !data.unknown) {
        Dialog({
          content: t('components.quitList.filterWarning'),
          btns: [{ text: t('ok') }]
        })
        return
      }
      query()
    }

    return { ...toRefs(data), t, query, pagePrev, page, pageNext, orderBy, goMember, filterBlur }
  }
}
</script>

<style lang="scss" scoped>
.quit-list {
  width: 100%;
  border-radius: 5px;
  table-layout: fixed;
  tr {
    height: 40px;
    th {
      p {
        display: table;
      }
      .up::after {
        content: "";
        display: table-cell;
        vertical-align: middle;
        width: 10px;
        height: 10px;
        opacity: 0.4;
        background: no-repeat center center / 100% auto url('../assets/img/sort-up.svg');
      }
      .down::after {
        content: "";
        display: table-cell;
        vertical-align: middle;
        width: 10px;
        height: 10px;
        opacity: 0.4;
        background: no-repeat center center / 100% auto url('../assets/img/sort-down.svg');
      }
    }
    th, td {
      padding: 0 0.5em;
      text-align: center;
      p {
        width: 100%;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .serial {
      width: 40px;
    }
    .username {
      width: 120px;
    }
    .total {
      width: 80px;
      cursor: pointer;
    }
    .time {
      width: 150px;
      cursor: pointer;
    }
    .by {
      width: 100px;
    }
    .reason {
      width: 70px;
    }
    .blocked {
      width: 70px;
    }
    .nickname-td {
      display: table;
      table-layout: fixed;
      span {
        display: table-cell;
        vertical-align: middle;
        text-align: left;
      }
      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: no-repeat center center / cover gray;
      }
      .name {
        padding: 0 6px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .action-bar {
    .operate {
      display: table;
      width: 100%;
      > div {
        display: table-cell;
        vertical-align: middle;
      }
      .select-all {
        width: 80px;
        padding-left: 4px;
        text-align: left;
        div {
          display: inline-block;
        }
      }
      .pagination {
        > div {
          display: table-cell;
          vertical-align: middle;
        }
        .page {
          a, span, em {
            display: table-cell;
            vertical-align: middle;
          }
          a {
            height: 10px;
            padding: 0 10px;
            border-radius: 3px;
            svg {
              width: 12px;
            }
          }
          a:hover {
            color: #fff;
            background-color: #e54d26;
            svg {
              fill: #fff;
            }
          }
          span {
            padding: 0 5px;
            em {
              padding: 0 2px;
              i {
                color: #fff;
                display: block;
                width: 28px;
                height: 20px;
                font-size: 14px;
                line-height: 20px;
                border-radius: 3px;
                background: #60b7ff;
              }
              i:hover {
                background: rgba(96, 183, 255, 0.6);
              }
            }
          }
        }
        .all {
          span {
            color: gray;
            margin: 0 15px 0 10px;
            font-size: 14px;
            white-space: nowrap;
          }
        }
        .limit {
          select {
            height: 25px;
            margin-right: 18px;
            padding: 0 2px;
            cursor: pointer;
            border-radius: 3px;
            border: 1px solid gray;
          }
        }
        .reason-checkbox {
          cursor: pointer;
          position: relative;
          font-size: 14px;
          p {
            padding: 2px 20px 2px 8px;
            border-radius: 3px;
            border: 1px solid gray;
            position: relative;
          }
          p::after {
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            top: 50%;
            right: 4px;
            margin-top: -5px;
            background: no-repeat center center / cover;
            background-image: url('../assets/img/sort-down.svg');
          }
          ul {
            position: absolute;
            top: 0;
            left: 0;
            margin: -2px 0 0 -2px;
            padding: 4px 10px;
            border-radius: 6px;
            background: #eee;
            border: 1px solid #ababab;
            li {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
</style>
