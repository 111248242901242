<template>
  <div class="app member-by-quit">
    <Navigation/>
    <PanelSidebar/>
    <div class="panel-content member-by-quit-content">
      <Head :title="title" :docPath="docPath"/>
      <div class="member-by-quit-content-in" :style="{visibility: list === null ? 'hidden' : 'visible'}">
        <List :total="total" :list="list" @reload="query"/>
      </div>
    </div>
  </div>
</template>

<script>
import List from '../../components/QuitList.vue'
import Head from '../../components/PanelHead.vue'
import Navigation from '../../components/PanelNav.vue'
import PanelSidebar from '../../components/PanelSidebar.vue'
import { useI18n } from 'vue-i18n'
import { useDialog } from '../../utils/dialog.js'
import { reactive, toRefs } from '@vue/reactivity'
import { membersByQuitApi } from '../../api/index.js'

export default {
  name: 'Quit',
  components: {
    Head,
    List,
    Navigation,
    PanelSidebar
  },
  setup() {
    const { t } = useI18n()
    const Dialog = useDialog()
    const data = reactive({
      title: t('membersByQuit.head.title'),
      docPath: t('membersByQuit.head.docPath'),

      loading: false,
      total: 0,
      list: null
    })

    const query = (sort, order, limit, offset, reg, url, self, mention, verify, timeout, nickname, via, warnVia, unknown) => {
      if (data.loading) return
      Dialog({ type: 'loading' })
      data.loading = true
      data.list = null
      membersByQuitApi({
        sort: sort,
        order: order,
        limit: limit,
        offset: offset,
        reg: reg,
        url: url,
        self: self,
        mention: mention,
        verify: verify,
        timeout: timeout,
        nickname: nickname,
        via: via,
        warnVia: warnVia,
        unknown: unknown
      }).then(succ => {
        if (succ.code === 10000) {
          data.total = succ.data.total
          data.list = succ.data.list
        }
      }).finally(() => {
        Dialog({ remove: 'true' })
        data.loading = false
      })
    }

    return { ...toRefs(data), t, query }
  }
}
</script>
